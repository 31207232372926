.page-user,
.page-user-edit {
  .content-inner {
    padding: 40px;
    box-shadow: 0 2px 10px 0 rgba(60, 87, 223, 0.1);
    margin: 40px auto;
    max-width: 700px;
    .form-actions {
      margin-top: 20px;
    }
    input {
      transition: all 0.4s ease;
      border: 1px solid $primary-op-4;
      &:focus {
        outline: 0;
      }
    }
    .form-type-textfield input {
      width: 100%;
    }
  }
}
.page-user .content-inner .form-type-password input {
  width: 100%;
}
.page-user-edit .content-inner .form-type-password {
  @include mq("tablet", max) {
    width: 100%;
  }
  .password-strength {
    float: none;
    margin: 20px 0;
  }
  input {
    width: auto;
  }
}
.form-item {
  text-align: left;
  input {
    border: 1px solid $primary-op-4;
  }
}
