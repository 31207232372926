.header .region-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  #block-superfish-3 {
    order: 2;
    margin-left: 15px;
    @include mq("desktop-small", max) {
      margin-right: 15px;
    }
    @include mq("tablet", max) {
      margin-right: 40px;
    }
    select {
      -webkit-appearance: none;
      outline: none;
      background-color: $white;
      padding: 4px 20px 4px 10px;
      color: $primary--color;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $black;
    }
    ul li.firstandlast {
      &.active-trail a {
        border-bottom: 0;
      }
      &.sf-no-children a {
        background-color: $primary-op-8;
        padding: 10px;
        color: $white;
        margin-bottom: 5px;
      }
      a.menuparent {
        background: url(images/down.svg) no-repeat top 50% right 10px /
          9px;
        background-color: $primary-op-8;
        padding: 10px 22px 10px 10px;
        margin-bottom: 5px;
        color: $white;
        span {
          display: none;
        }
      }
      position: relative;
      ul {
        padding: 18px !important;
        background: rgba(255, 255, 255, 0.8);
        max-width: 120px;
        margin: 18px auto 0;
        border-radius: 0;
        li {
          a {
            border: 0;
            padding-bottom: 5px;
            color: $primary--color;
            &:hover {
              color: $black;
            }
            &.active {
              border-bottom: 1px solid $black;
            }
          }
        }
      }
    }
  }
}
.logged-in {
  .header {
    padding-top: 0;
  }
}
.logged-in.admin-menu {
  &.mean-container a.meanmenu-reveal {
    @include mq("desktop", max) {
      top: 57px !important;
    }
  }
  .header {
    padding-top: 30px;
  }
}
