.pane-notice {
  .view-notice .view-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 36px;
    .views-row {
      flex-basis: 33.3%;
      max-width: 33.3%;
      padding: 20px;
      @include mq("tablet-small", max) {
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: 10px;
        padding: 10px;
      }
      @include mq("phone-wide", max) {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        padding: 10px;
      }

      .views-field-title span a {
        font-size: 18px;
        color: $primary--color;
        margin-bottom: 10px;
        line-height: 26px;
        font-weight: 400;
      }
      .views-field-created span {
        color: #9b9b9b;
        font-size: 14px;
        line-height: 22px;
      }
      &:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        .views-field-title span a {
          //   color: #c30002;
          color: $light--blue;
        }
      }
    }
  }
}
