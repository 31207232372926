iframe {
  width: 100%;
}
.front #cboxContent{
  overflow: initial;

  #cboxClose {
    position: absolute;
    top: -13px;
    right: -13px;
    background: url(images/ic_close.svg) no-repeat center center / 25px;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
  }
}