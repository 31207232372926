/* Styling tabs for NBSM page */

.menu-name-menu-dashboard-menu,
.quicktabs-wrapper {
  ul {
    display: inline-flex;
    border: 0;
    li {
      padding: 10px;
      transition: all 0.4s ease;
      border: 1px solid $primary--color;
      list-style: none;
      &:not(:last-child) {
        border-right: 0;
      }
      a {
        color: $primary--color;
      }
      @include mq("phablet", max) {
        font-size: 12px;
        padding: 6px;
      }
    }
    li:hover,
    li.active {
      background-color: $primary--color;
      color: $white;
      a {
        color: $white;
      }
    }
    li.active {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 85%;
        left: 0;
        margin: auto;
        right: 0;
        height: 10px;
        width: 10px;
        border-top: 1px solid $primary--color;
        border-right: 1px solid $primary--color;
        background-color: $primary--color;
        transform: rotate(135deg);
        transition: all 0.4s ease;
        @include mq("phablet", max) {
          display: none;
        }
      }
    }
  }

  .item-list,
  .view-filters {
    margin-bottom: 10px;
  }
}

.view-id-label_issue {
  text-align: center;
}
.view-id-label_issue {
  ul.pager {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    li {
      font-size: 12px;
      padding: 2px 10px;
      &.pager-item,
      &.pager-first,
      &.pager-last,
      &.pager-ellipsis {
        display: none;
      }
      &.pager-previous,
      &.pager-next {
        border: 1px solid $primary--color;
      }
      &:hover,
      &.pager-current {
        background-color: $primary--color;
        color: $white;
        a {
          color: $white;
        }
      }
    }
  }
}
