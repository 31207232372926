.views-field-field-sample-status {
  span {
    color: #fff;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 2px;
    font-weight: bold;
    // Registered
    &.sample-status-32 {
      background-color: #008080;
    }
    //Sent
    &.sample-status-33 {
      background-color: #f4b400;
    }
    //Processing
    &.sample-status-34 {
      background-color: #4285f4;
    }
    // Completed
    &.sample-status-35 {
      background-color: #0f9d58;
    }
  }
}
.views-field-field-sample-result span {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 2px;
  &.result-36 {
    background-color: #db4437;
    color: $white;
    font-weight: bold;
  }
}
