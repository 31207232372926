#colorbox {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  #cboxContent {
    @include mq("tablet", max) {
      padding: 20px;
    }
  }
}
