// styling dahsboard tables

.contextual-links-region {
  .block__title {
    margin-top: 0;
  }
}
.dashboard-table {
  .top-view-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    .view-header {
      text-align: right;
      margin-bottom: 20px;
      .add-btn {
        border: 1px solid$primary--color;
        color: $primary--color;
        padding: 10px;
        transition: all 0.4s ease;
        &:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 20px;
          background: url(images/more.svg) no-repeat;
          vertical-align: middle;
          margin-right: 7px;
          transition: all 0.4s ease;
        }
        &:hover {
          // box-shadow: 0 2px 40px 0 $primary--color;
          background-color: $primary--color;
          color: $white;
          &:before {
            background: url(images/more-white.svg) no-repeat;
          }
        }
      }
      @include mq("phone-wide", max) {
        width: 100%;
        text-align: left;
      }
    }
    .view-filters {
      width: 50%;
      @include mq("phone-wide", max) {
        width: 100%;
      }
    }
  }
  .view-content {
    width: 100%;
  }
  .item-list {
    width: 100%;
  }

  table {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    // min-width: 590px;
    // margin: 20px auto 40px;
    font-size: 14px;
    @include mq("tablet", max) {
      margin: 0 auto 20px;
      box-shadow: none;
    }
    tbody,
    thead {
      tr {
        th,
        td {
          padding: 8px;
          text-align: left;
        }
      }
    }
    thead {
      tr {
        border-bottom: 1px solid #eee;
        background: #f6f9fa;
        th {
          a {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            img {
              height: 10px;
              width: 10px;
            }
          }
          a[href$="asc"],
          a[href$="dsc"] {
            color: #006a9c;
          }
        }
        .views-field-edit-node {
          position: static;
        }
      }
    }
    tbody tr {
      border-bottom: 1px solid #eee;
      @include mq("tablet", max) {
        margin-bottom: 20px;
        border: 0;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
      }
      &:last-child {
        border: 0;
      }
      &:hover {
        background: #f6f9fa;
      }
      td.active {
        background-color: #f6f9fa;
      }

      //   keeping edit button on right side
    }
  }
  .views-field-edit-node {
    position: static;
    min-height: 38px;
    a {
      margin: 0;
      padding: 0;
      background-color: transparent;
      color: $primary--color;
      &:after {
        content: "";
        display: inline-block;
        background-image: url(images/edit.svg);
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        margin-left: 6px;
      }
    }
  }
}
#quicktabs-member_dashboard {
  .view-id-members {
    padding: 40px;
    display: flex;
    max-width: 556px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    .views-row {
      display: flex;
      flex-wrap: wrap;
      .views-field-name,
      .views-field-field-member-from,
      .views-field-field-membership-renewed {
        width: 100%;
        display: inline-flex;
        align-items: center;
        .field-content {
          margin-left: 20px;
        }
        .views-label {
        }
      }
      .views-field-field-membership-renewed {
        order: -1;
        .field-content {
          font-size: 24px;
        }
      }
    }
  }
}
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

@media only screen and (max-width: 767px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tbody td.views-field-edit-node {
    position: static;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 150px !important;
    min-height: 38px;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    // top: 8px;
    left: 8px;
    width: 30%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
  /*
	Label the data for table headings in dashboard-am/member page/ NPIA page
  */
  .dashboard-table {
    td.views-field-counter:before {
      content: "SN";
    }
    td.views-field-field-producer:before {
      content: "Company ";
    }
    td.views-field-field-date-issue:before {
      content: "Date of Issue";
    }
    td.views-field-field-trade-mark:before {
      content: "Issue Number";
    }
    td.views-field-nid:before {
      content: "Sample ID";
    }
    td.views-field-changed:before {
      content: "Updated";
    }
    td.views-field-field-sample-status:before {
      content: "Status";
    }
    td.views-field-field-sample-result:before {
      content: "Result";
    }
    td.views-field-edit-node:before {
      content: "Operation";
    }
    td.views-field-ctools-dropdown:before {
      content: "Operation";
    }
    td.views-field-field-company-name:before {
      content: "Company/Firm";
    }
    td.views-field-name:before {
      content: "Username";
    }
    td.views-field-field-member-from:before {
      content: "Member From";
    }
    td.views-field-field-membership-renewed:before {
      content: "Renewed Date";
    }
    td.views-field-mail:before {
      content: "E-mail";
    }
    td.views-field-field-phone-number:before {
      content: "Phone Number";
    }
  }
}

// styling page header
.logged-in.page-dashboard-nbsm,
.logged-in.page-dashboard-member {
  .content-wrap {
    padding-top: 182px;
    .title-sec {
      color: $primary--color;
    }
    @include mq("phone-wide", max) {
      padding-top: 142px;
    }
  }
}

// login form
.form-submit:hover,
.form-submit:focus {
  color: $white;
}
// .views-widget-filter-nid,
// .views-widget-filter-field_organization_name_value {
//   margin-bottom: 20px;
// }

.views-field.views-field-ctools-dropdown {
  @include mq("tablet", max) {
    min-height: 42px;
  }
  .ctools-dropbutton {
    position: absolute;
    background-color: $white;
  }
}

//removing title and revision information section from nbsm logged-in page
.logged-in .node-sample-form {
  .form-item-title,
  .vertical-tabs {
    display: none;
  }
}
// adding padding to content in dashboard of admin
.admin-menu {
  &.page-dashboard-am,
  &.page-dashboard-as,
  &.page-dashboard-label,
  &.page-dashboard-contact {
    .content-wrap {
      padding-top: 160px;
    }
  }
  &.page-mass-contact {
    .content-wrap {
      padding-top: 200px;
      @include mq("phone-wide", max) {
        padding-top: 160px;
      }
    }
  }
}
.page-dashboard-nbsm .dashboard-table .top-view-content {
  flex-direction: row;
}
