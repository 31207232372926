.page-mass-contact {
  //   background-color: #f8f9fd;
  .title-wrap .title-sec {
    max-width: 1280px;
    padding: 0 30px;
    text-align: left;
    .page-title {
      color: $primary--color;
      font-size: 24px;
      @include mq("phone-wide") {
        margin-bottom: 60px;
      }
    }
  }
  #mass-contact-mail-page {
    margin: 20px 0;
    max-width: 700px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;

    input,
    textarea {
      transition: all 0.4s ease;
      border: 1px solid $primary-op-4;
      &:focus {
        outline: 0;
      }
    }
    .form-type-textfield input {
      width: 100%;
    }
    .form-type-checkbox {
      input {
        display: none;
      }
      label {
        padding-left: 25px;
        position: relative;
        &:before,
        &:after {
          content: "";
          display: block;
          // border-radius: 2px;
          position: absolute;
          transition: all 0.4s ease;
        }
        &:before {
          width: 18px;
          height: 18px;
          border: 3px solid $primary-op-8;
          left: 0;
          top: 3px;
        }
        &:after {
          width: 8px;
          height: 8px;
          background-color: $primary--color;
          top: 8px;
          left: 5px;
          opacity: 0;
        }
      }
      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
    }
    .form-submit {
      margin: 20px 0;
      transition: all 0.4s ease;
      &:hover {
        background-color: transparent;
        border: 1px solid $primary-op-8;
        color: $primary--color;
      }
    }
  }
}
