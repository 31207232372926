.page-node-564 .content-wrap .layout-center{
  max-width: 800px;
}
.node-type-sample,
.node-type-notice,
.node-type-product-trade-mark-data{
  .title-wrap .region-feature-image .views-field-field-feature-image .field-content{
    background: url(images/wholesaler-cover.jpg) no-repeat center center / cover;
    height: initial;
    min-height: 250px;
  }
}
// styling download page
.files-uploaded ul {
  li {
    background: #eeeeee;
    margin: 0 0 1px 0;
    padding: 10px;
    span {
      display: inline-flex;
      //   background: url(images/document.svg) no-repeat center left/20px;
      //   padding-left: 30px;
      display: block;
      img {
        display: none;
      }
      a {
        background: url(images/download.svg) no-repeat center
          right/20px;
        font-size: 16px;
        padding-right: 20px;
        display: inline-flex;
        align-items: center;
        width: 100%;
        &:before {
          content: "";
          display: inline-block;
          background: url(images/document.svg) no-repeat;
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
      a[href$=".pdf"]:before {
        background: url(images/ic_pdf.svg) no-repeat;
      }
      a[href$=".xlsx"]:before {
        background: url(images/ic_excel.svg) no-repeat;
      }
      a[href$=".docx"]:before {
        background: url(images/ic_word.svg) no-repeat;
      }
    }
    &:hover {
      //   background-color: rgba(79, 76, 77, 0.6);
      background-color: $light--blue;
      span {
        // background: url(images/document-white.svg) no-repeat center
        // left/20px;
        a {
          color: #fff;
          background: url(images/download-white.svg) no-repeat center
            right/20px;
          &:before {
            background: url(images/document-white.svg) no-repeat;
          }
        }
        a[href$=".pdf"]:before {
          background: url(images/ic_pdf-white.svg) no-repeat;
        }
        a[href$=".xlsx"]:before {
          background: url(images/ic_excel-white.svg) no-repeat;
        }
        a[href$=".docx"]:before {
          background: url(images/ic_word-white.svg) no-repeat;
        }
      }
    }
  }
}

.field-type-text-with-summary .field-items {
  padding: 20px;
  box-shadow: 0 2px 10px 0 rgba(60, 87, 223, 0.1);
  //   background-color: $light--blue;
  margin: 20px 0;
}

.node-product-trade-mark-data, .node-sample{
  .field{
    margin-bottom: 20px;
  }
}
