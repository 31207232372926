.views-field-field-sample-status span {
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 2px;
  font-weight: bold;
}

.views-field-field-sample-status span.sample-status-32 {
  background-color: #008080;
}

.views-field-field-sample-status span.sample-status-33 {
  background-color: #f4b400;
}

.views-field-field-sample-status span.sample-status-34 {
  background-color: #4285f4;
}

.views-field-field-sample-status span.sample-status-35 {
  background-color: #0f9d58;
}

.views-field-field-sample-result span {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 2px;
}

.views-field-field-sample-result span.result-36 {
  background-color: #db4437;
  color: #fff;
  font-weight: bold;
}

.example-block {
  *zoom: 1;
}

.example-block:before,
.example-block:after {
  content: " ";
  display: table;
}

.example-block:after {
  clear: both;
}

a {
  display: inline-block;
  color: #333333;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
  color: #1a1a1a;
  transition: all 0.3s ease-in-out;
}

.contextual-links-region .block__title {
  margin-top: 0;
}

.dashboard-table .top-view-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.dashboard-table .top-view-content .view-header {
  text-align: right;
  margin-bottom: 20px;
}

.dashboard-table .top-view-content .view-header .add-btn {
  border: 1px solid #4f4c4d;
  color: #4f4c4d;
  padding: 10px;
  transition: all 0.4s ease;
}

.dashboard-table .top-view-content .view-header .add-btn:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 20px;
  background: url(../images/more.svg) no-repeat;
  vertical-align: middle;
  margin-right: 7px;
  transition: all 0.4s ease;
}

.dashboard-table .top-view-content .view-header .add-btn:hover {
  background-color: #4f4c4d;
  color: #fff;
}

.dashboard-table .top-view-content .view-header .add-btn:hover:before {
  background: url(../images/more-white.svg) no-repeat;
}

@media only screen and (max-width: 479px) {
  .dashboard-table .top-view-content .view-header {
    width: 100%;
    text-align: left;
  }
}

.dashboard-table .top-view-content .view-filters {
  width: 50%;
}

@media only screen and (max-width: 479px) {
  .dashboard-table .top-view-content .view-filters {
    width: 100%;
  }
}

.dashboard-table .view-content {
  width: 100%;
}

.dashboard-table .item-list {
  width: 100%;
}

.dashboard-table table {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .dashboard-table table {
    margin: 0 auto 20px;
    box-shadow: none;
  }
}

.dashboard-table table tbody tr th,
.dashboard-table table tbody tr td,
.dashboard-table table thead tr th,
.dashboard-table table thead tr td {
  padding: 8px;
  text-align: left;
}

.dashboard-table table thead tr {
  border-bottom: 1px solid #eee;
  background: #f6f9fa;
}

.dashboard-table table thead tr th a {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dashboard-table table thead tr th a img {
  height: 10px;
  width: 10px;
}

.dashboard-table table thead tr th a[href$=asc],
.dashboard-table table thead tr th a[href$=dsc] {
  color: #006a9c;
}

.dashboard-table table thead tr .views-field-edit-node {
  position: static;
}

.dashboard-table table tbody tr {
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 767px) {
  .dashboard-table table tbody tr {
    margin-bottom: 20px;
    border: 0;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  }
}

.dashboard-table table tbody tr:last-child {
  border: 0;
}

.dashboard-table table tbody tr:hover {
  background: #f6f9fa;
}

.dashboard-table table tbody tr td.active {
  background-color: #f6f9fa;
}

.dashboard-table .views-field-edit-node {
  position: static;
  min-height: 38px;
}

.dashboard-table .views-field-edit-node a {
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: #4f4c4d;
}

.dashboard-table .views-field-edit-node a:after {
  content: "";
  display: inline-block;
  background-image: url(../images/edit.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  margin-left: 6px;
}

#quicktabs-member_dashboard .view-id-members {
  padding: 40px;
  display: flex;
  max-width: 556px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

#quicktabs-member_dashboard .view-id-members .views-row {
  display: flex;
  flex-wrap: wrap;
}

#quicktabs-member_dashboard .view-id-members .views-row .views-field-name,
#quicktabs-member_dashboard .view-id-members .views-row .views-field-field-member-from,
#quicktabs-member_dashboard .view-id-members .views-row .views-field-field-membership-renewed {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

#quicktabs-member_dashboard .view-id-members .views-row .views-field-name .field-content,
#quicktabs-member_dashboard .view-id-members .views-row .views-field-field-member-from .field-content,
#quicktabs-member_dashboard .view-id-members .views-row .views-field-field-membership-renewed .field-content {
  margin-left: 20px;
}

#quicktabs-member_dashboard .view-id-members .views-row .views-field-field-membership-renewed {
  order: -1;
}

#quicktabs-member_dashboard .view-id-members .views-row .views-field-field-membership-renewed .field-content {
  font-size: 24px;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

@media only screen and (max-width: 767px) {
  /* Force table to not be like tables anymore */

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tbody td.views-field-edit-node {
    position: static;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 150px !important;
    min-height: 38px;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    left: 8px;
    width: 30%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }

  /*
  Label the data for table headings in dashboard-am/member page/ NPIA page
   */

  .dashboard-table td.views-field-counter:before {
    content: "SN";
  }

  .dashboard-table td.views-field-field-producer:before {
    content: "Company ";
  }

  .dashboard-table td.views-field-field-date-issue:before {
    content: "Date of Issue";
  }

  .dashboard-table td.views-field-field-trade-mark:before {
    content: "Issue Number";
  }

  .dashboard-table td.views-field-nid:before {
    content: "Sample ID";
  }

  .dashboard-table td.views-field-changed:before {
    content: "Updated";
  }

  .dashboard-table td.views-field-field-sample-status:before {
    content: "Status";
  }

  .dashboard-table td.views-field-field-sample-result:before {
    content: "Result";
  }

  .dashboard-table td.views-field-edit-node:before {
    content: "Operation";
  }

  .dashboard-table td.views-field-ctools-dropdown:before {
    content: "Operation";
  }

  .dashboard-table td.views-field-field-company-name:before {
    content: "Company/Firm";
  }

  .dashboard-table td.views-field-name:before {
    content: "Username";
  }

  .dashboard-table td.views-field-field-member-from:before {
    content: "Member From";
  }

  .dashboard-table td.views-field-field-membership-renewed:before {
    content: "Renewed Date";
  }

  .dashboard-table td.views-field-mail:before {
    content: "E-mail";
  }

  .dashboard-table td.views-field-field-phone-number:before {
    content: "Phone Number";
  }
}

.logged-in.page-dashboard-nbsm .content-wrap,
.logged-in.page-dashboard-member .content-wrap {
  padding-top: 182px;
}

.logged-in.page-dashboard-nbsm .content-wrap .title-sec,
.logged-in.page-dashboard-member .content-wrap .title-sec {
  color: #4f4c4d;
}

@media only screen and (max-width: 479px) {
  .logged-in.page-dashboard-nbsm .content-wrap,
  .logged-in.page-dashboard-member .content-wrap {
    padding-top: 142px;
  }
}

.form-submit:hover,
.form-submit:focus {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .views-field.views-field-ctools-dropdown {
    min-height: 42px;
  }
}

.views-field.views-field-ctools-dropdown .ctools-dropbutton {
  position: absolute;
  background-color: #fff;
}

.logged-in .node-sample-form .form-item-title,
.logged-in .node-sample-form .vertical-tabs {
  display: none;
}

.admin-menu.page-dashboard-am .content-wrap,
.admin-menu.page-dashboard-as .content-wrap,
.admin-menu.page-dashboard-label .content-wrap,
.admin-menu.page-dashboard-contact .content-wrap {
  padding-top: 160px;
}

.admin-menu.page-mass-contact .content-wrap {
  padding-top: 200px;
}

@media only screen and (max-width: 479px) {
  .admin-menu.page-mass-contact .content-wrap {
    padding-top: 160px;
  }
}

.page-dashboard-nbsm .dashboard-table .top-view-content {
  flex-direction: row;
}

.header .region-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.header .region-header #block-superfish-3 {
  order: 2;
  margin-left: 15px;
}

@media only screen and (max-width: 1023px) {
  .header .region-header #block-superfish-3 {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header .region-header #block-superfish-3 {
    margin-right: 40px;
  }
}

.header .region-header #block-superfish-3 select {
  -webkit-appearance: none;
  outline: none;
  background-color: #fff;
  padding: 4px 20px 4px 10px;
  color: #4f4c4d;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #000;
}

.header .region-header #block-superfish-3 ul li.firstandlast {
  position: relative;
}

.header .region-header #block-superfish-3 ul li.firstandlast.active-trail a {
  border-bottom: 0;
}

.header .region-header #block-superfish-3 ul li.firstandlast.sf-no-children a {
  background-color: rgba(79, 76, 77, 0.8);
  padding: 10px;
  color: #fff;
  margin-bottom: 5px;
}

.header .region-header #block-superfish-3 ul li.firstandlast a.menuparent {
  background: url(../images/down.svg) no-repeat top 50% right 10px/9px;
  background-color: rgba(79, 76, 77, 0.8);
  padding: 10px 22px 10px 10px;
  margin-bottom: 5px;
  color: #fff;
}

.header .region-header #block-superfish-3 ul li.firstandlast a.menuparent span {
  display: none;
}

.header .region-header #block-superfish-3 ul li.firstandlast ul {
  padding: 18px !important;
  background: rgba(255, 255, 255, 0.8);
  max-width: 120px;
  margin: 18px auto 0;
  border-radius: 0;
}

.header .region-header #block-superfish-3 ul li.firstandlast ul li a {
  border: 0;
  padding-bottom: 5px;
  color: #4f4c4d;
}

.header .region-header #block-superfish-3 ul li.firstandlast ul li a:hover {
  color: #000;
}

.header .region-header #block-superfish-3 ul li.firstandlast ul li a.active {
  border-bottom: 1px solid #000;
}

.logged-in .header {
  padding-top: 0;
}

@media only screen and (max-width: 1247px) {
  .logged-in.admin-menu.mean-container a.meanmenu-reveal {
    top: 57px !important;
  }
}

.logged-in.admin-menu .header {
  padding-top: 30px;
}

/* Styling tabs for NBSM page */

.menu-name-menu-dashboard-menu ul,
.quicktabs-wrapper ul {
  display: inline-flex;
  border: 0;
}

.menu-name-menu-dashboard-menu ul li,
.quicktabs-wrapper ul li {
  padding: 10px;
  transition: all 0.4s ease;
  border: 1px solid #4f4c4d;
  list-style: none;
}

.menu-name-menu-dashboard-menu ul li:not(:last-child),
.quicktabs-wrapper ul li:not(:last-child) {
  border-right: 0;
}

.menu-name-menu-dashboard-menu ul li a,
.quicktabs-wrapper ul li a {
  color: #4f4c4d;
}

@media only screen and (max-width: 559px) {
  .menu-name-menu-dashboard-menu ul li,
  .quicktabs-wrapper ul li {
    font-size: 12px;
    padding: 6px;
  }
}

.menu-name-menu-dashboard-menu ul li:hover,
.menu-name-menu-dashboard-menu ul li.active,
.quicktabs-wrapper ul li:hover,
.quicktabs-wrapper ul li.active {
  background-color: #4f4c4d;
  color: #fff;
}

.menu-name-menu-dashboard-menu ul li:hover a,
.menu-name-menu-dashboard-menu ul li.active a,
.quicktabs-wrapper ul li:hover a,
.quicktabs-wrapper ul li.active a {
  color: #fff;
}

.menu-name-menu-dashboard-menu ul li.active,
.quicktabs-wrapper ul li.active {
  position: relative;
}

.menu-name-menu-dashboard-menu ul li.active:after,
.quicktabs-wrapper ul li.active:after {
  content: "";
  position: absolute;
  top: 85%;
  left: 0;
  margin: auto;
  right: 0;
  height: 10px;
  width: 10px;
  border-top: 1px solid #4f4c4d;
  border-right: 1px solid #4f4c4d;
  background-color: #4f4c4d;
  transform: rotate(135deg);
  transition: all 0.4s ease;
}

@media only screen and (max-width: 559px) {
  .menu-name-menu-dashboard-menu ul li.active:after,
  .quicktabs-wrapper ul li.active:after {
    display: none;
  }
}

.menu-name-menu-dashboard-menu .item-list,
.menu-name-menu-dashboard-menu .view-filters,
.quicktabs-wrapper .item-list,
.quicktabs-wrapper .view-filters {
  margin-bottom: 10px;
}

.view-id-label_issue {
  text-align: center;
}

.view-id-label_issue ul.pager {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.view-id-label_issue ul.pager li {
  font-size: 12px;
  padding: 2px 10px;
}

.view-id-label_issue ul.pager li.pager-item,
.view-id-label_issue ul.pager li.pager-first,
.view-id-label_issue ul.pager li.pager-last,
.view-id-label_issue ul.pager li.pager-ellipsis {
  display: none;
}

.view-id-label_issue ul.pager li.pager-previous,
.view-id-label_issue ul.pager li.pager-next {
  border: 1px solid #4f4c4d;
}

.view-id-label_issue ul.pager li:hover,
.view-id-label_issue ul.pager li.pager-current {
  background-color: #4f4c4d;
  color: #fff;
}

.view-id-label_issue ul.pager li:hover a,
.view-id-label_issue ul.pager li.pager-current a {
  color: #fff;
}

.page-mass-contact .title-wrap .title-sec {
  max-width: 1280px;
  padding: 0 30px;
  text-align: left;
}

.page-mass-contact .title-wrap .title-sec .page-title {
  color: #4f4c4d;
  font-size: 24px;
}

@media only screen and (min-width: 480px) {
  .page-mass-contact .title-wrap .title-sec .page-title {
    margin-bottom: 60px;
  }
}

.page-mass-contact #mass-contact-mail-page {
  margin: 20px 0;
  max-width: 700px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.page-mass-contact #mass-contact-mail-page input,
.page-mass-contact #mass-contact-mail-page textarea {
  transition: all 0.4s ease;
  border: 1px solid rgba(79, 76, 77, 0.4);
}

.page-mass-contact #mass-contact-mail-page input:focus,
.page-mass-contact #mass-contact-mail-page textarea:focus {
  outline: 0;
}

.page-mass-contact #mass-contact-mail-page .form-type-textfield input {
  width: 100%;
}

.page-mass-contact #mass-contact-mail-page .form-type-checkbox input {
  display: none;
}

.page-mass-contact #mass-contact-mail-page .form-type-checkbox label {
  padding-left: 25px;
  position: relative;
}

.page-mass-contact #mass-contact-mail-page .form-type-checkbox label:before,
.page-mass-contact #mass-contact-mail-page .form-type-checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  transition: all 0.4s ease;
}

.page-mass-contact #mass-contact-mail-page .form-type-checkbox label:before {
  width: 18px;
  height: 18px;
  border: 3px solid rgba(79, 76, 77, 0.8);
  left: 0;
  top: 3px;
}

.page-mass-contact #mass-contact-mail-page .form-type-checkbox label:after {
  width: 8px;
  height: 8px;
  background-color: #4f4c4d;
  top: 8px;
  left: 5px;
  opacity: 0;
}

.page-mass-contact #mass-contact-mail-page .form-type-checkbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}

.page-mass-contact #mass-contact-mail-page .form-submit {
  margin: 20px 0;
  transition: all 0.4s ease;
}

.page-mass-contact #mass-contact-mail-page .form-submit:hover {
  background-color: transparent;
  border: 1px solid rgba(79, 76, 77, 0.8);
  color: #4f4c4d;
}

#colorbox {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px) {
  #colorbox #cboxContent {
    padding: 20px;
  }
}

.page-node-564 .content-wrap .layout-center {
  max-width: 800px;
}

.node-type-sample .title-wrap .region-feature-image .views-field-field-feature-image .field-content,
.node-type-notice .title-wrap .region-feature-image .views-field-field-feature-image .field-content,
.node-type-product-trade-mark-data .title-wrap .region-feature-image .views-field-field-feature-image .field-content {
  background: url(../images/wholesaler-cover.jpg) no-repeat center center/cover;
  height: initial;
  min-height: 250px;
}

.files-uploaded ul li {
  background: #eeeeee;
  margin: 0 0 1px 0;
  padding: 10px;
}

.files-uploaded ul li span {
  display: inline-flex;
  display: block;
}

.files-uploaded ul li span img {
  display: none;
}

.files-uploaded ul li span a {
  background: url(../images/download.svg) no-repeat center right/20px;
  font-size: 16px;
  padding-right: 20px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.files-uploaded ul li span a:before {
  content: "";
  display: inline-block;
  background: url(../images/document.svg) no-repeat;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.files-uploaded ul li span a[href$=".pdf"]:before {
  background: url(../images/ic_pdf.svg) no-repeat;
}

.files-uploaded ul li span a[href$=".xlsx"]:before {
  background: url(../images/ic_excel.svg) no-repeat;
}

.files-uploaded ul li span a[href$=".docx"]:before {
  background: url(../images/ic_word.svg) no-repeat;
}

.files-uploaded ul li:hover {
  background-color: #5a7190;
}

.files-uploaded ul li:hover span a {
  color: #fff;
  background: url(../images/download-white.svg) no-repeat center right/20px;
}

.files-uploaded ul li:hover span a:before {
  background: url(../images/document-white.svg) no-repeat;
}

.files-uploaded ul li:hover span a[href$=".pdf"]:before {
  background: url(../images/ic_pdf-white.svg) no-repeat;
}

.files-uploaded ul li:hover span a[href$=".xlsx"]:before {
  background: url(../images/ic_excel-white.svg) no-repeat;
}

.files-uploaded ul li:hover span a[href$=".docx"]:before {
  background: url(../images/ic_word-white.svg) no-repeat;
}

.field-type-text-with-summary .field-items {
  padding: 20px;
  box-shadow: 0 2px 10px 0 rgba(60, 87, 223, 0.1);
  margin: 20px 0;
}

.node-product-trade-mark-data .field,
.node-sample .field {
  margin-bottom: 20px;
}

.pane-notice .view-notice .view-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 36px;
}

.pane-notice .view-notice .view-content .views-row {
  flex-basis: 33.3%;
  max-width: 33.3%;
  padding: 20px;
}

@media only screen and (max-width: 639px) {
  .pane-notice .view-notice .view-content .views-row {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 10px;
    padding: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .pane-notice .view-notice .view-content .views-row {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 10px;
  }
}

.pane-notice .view-notice .view-content .views-row .views-field-title span a {
  font-size: 18px;
  color: #4f4c4d;
  margin-bottom: 10px;
  line-height: 26px;
  font-weight: 400;
}

.pane-notice .view-notice .view-content .views-row .views-field-created span {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 22px;
}

.pane-notice .view-notice .view-content .views-row:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pane-notice .view-notice .view-content .views-row:hover .views-field-title span a {
  color: #5a7190;
}

iframe {
  width: 100%;
}

.front #cboxContent {
  overflow: initial;
}

.front #cboxContent #cboxClose {
  position: absolute;
  top: -13px;
  right: -13px;
  background: url(../images/ic_close.svg) no-repeat center center/25px;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}

.page-user .content-inner,
.page-user-edit .content-inner {
  padding: 40px;
  box-shadow: 0 2px 10px 0 rgba(60, 87, 223, 0.1);
  margin: 40px auto;
  max-width: 700px;
}

.page-user .content-inner .form-actions,
.page-user-edit .content-inner .form-actions {
  margin-top: 20px;
}

.page-user .content-inner input,
.page-user-edit .content-inner input {
  transition: all 0.4s ease;
  border: 1px solid rgba(79, 76, 77, 0.4);
}

.page-user .content-inner input:focus,
.page-user-edit .content-inner input:focus {
  outline: 0;
}

.page-user .content-inner .form-type-textfield input,
.page-user-edit .content-inner .form-type-textfield input {
  width: 100%;
}

.page-user .content-inner .form-type-password input {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .page-user-edit .content-inner .form-type-password {
    width: 100%;
  }
}

.page-user-edit .content-inner .form-type-password .password-strength {
  float: none;
  margin: 20px 0;
}

.page-user-edit .content-inner .form-type-password input {
  width: auto;
}

.form-item {
  text-align: left;
}

.form-item input {
  border: 1px solid rgba(79, 76, 77, 0.4);
}

