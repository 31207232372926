//Clearfix-Mixin

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//Example
.example-block {
  @extend %clearfix;
}