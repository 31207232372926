//Links-scss

$a-link: 'a';
$a-link-hover: 'a:hover,a:focus';

#{$a-link} {
  display: inline-block;
  color: $light--black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

#{$a-link-hover} {
  color: darken($light--black, 10%);
  transition: all 0.3s ease-in-out;
}

//.example-link-element {
//  a{
//    transition: all 0.3s linear;
//    text-decoration: none;
//  }
//
//  #{$a-link} {
//    color: $dark-pink;
//  }
//  #{$a-link-hover} {
//    color: $blue;
//  }
//}